$card-meta-color: #969696;
$card-bg-color: rgb(240, 248, 255);

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
    @if $inset {
        -webkit-box-shadow:inset $top $left $blur $color;
        -moz-box-shadow:inset $top $left $blur $color;
        box-shadow:inset $top $left $blur $color;
    } @else {
        -webkit-box-shadow: $top $left $blur $color;
        -moz-box-shadow: $top $left $blur $color;
        box-shadow: $top $left $blur $color;
    }
}

.project-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .project-card {
        border-radius: 2px;
        margin-right: 1rem;
        margin-bottom: 1rem;
        background: #fff;
        max-width: 275px;
        display: flex;
        flex-direction: column;
        flex-basis: 100%;

        h2 {
            a {
                text-decoration: none;
            }
            background-color: $card-bg-color;
            padding: 0.5rem;
        }

        section {
            flex-grow: 1;
            padding: 1rem;
        }

        .project-meta {
            padding: 0.5em 1em;
            div {
                text-transform: uppercase;
                font-size: 80%;
                color: $card-meta-color;
            }
        }

        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

        &:hover {
            box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
        }
    }
}
