body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.footer {
    margin-top: auto;
    display: flex;
    justify-content: center;
    background-color: $footer-color;
    color: #fffffc;
    padding: 5vh 10vh;
    height: 20vh;
}


.profile {
  color: #5995DA;
  padding: 16px 0;
  text-align: center;

  img {
    border-radius: 50%;
    width: 150px;
  }

  h1 {
    margin-top: 16px;
  }
}

.header {
    width: 900px;
    height: 300px;
    display: flex;
    justify-content: space-between;
}

.content-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 900px;
    margin: 0 auto;
    padding: 0 10vh;
    width: 100%;
}

.post-grid {
    max-width: 900px;
    display: flex;
    flex-direction: row;
}

.post-pane {
    display: block;
    max-width: 900px;
}

pre.highlight {
  padding: 0 !important;
  border-radius: 3px;
}


.post-preview {
    width: 200px;
    height: 200px;
}

.post {
    margin-bottom: 2vh;
}

.post-content {
}
